import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  TextField,
  Button,
  Box,
  Grid,
  Avatar,
  CircularProgress,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const UserInformationPage = () => {
  const [person, setPerson] = useState({
    patient_friend_name: '',
    patient_friend_email: '',
    patient_doctor_name: '',
    patient_doctor_email: '',
    patient_insurance_name: '',
    patient_insurance_email: '',
    mobile_number: '',
    address: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Assume type is passed as state from previous route
    const state = location.state;
    if (state && state.type) {
      setType(state.type);
    }

    // Add more initialization logic if needed
  }, [location.state]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPerson((prev) => ({ ...prev, [name]: value }));
  };

  const updatePatientsUserDetails = async (user) => {
    setIsLoading(true);
    try {
      // Replace with your API endpoint
      const response = await axios.put('/api/user', user);
      if (response.data.status) {
        // Navigate to the next page or show a success message
      } else {
        console.error('Update failed');
      }
    } catch (error) {
      console.error('error response', error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderForm = () => {
    if (type === 'friend') {
      return (
        <>
          <TextField
            label="Name"
            name="patient_friend_name"
            value={person.patient_friend_name}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Email"
            name="patient_friend_email"
            value={person.patient_friend_email}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Mobile Number"
            name="mobile_number"
            value={person.mobile_number}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Address"
            name="address"
            value={person.address}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
        </>
      );
    }

    if (type === 'doctor') {
      return (
        <>
          <TextField
            label="Name"
            name="patient_doctor_name"
            value={person.patient_doctor_name}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Email"
            name="patient_doctor_email"
            value={person.patient_doctor_email}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Mobile Number"
            name="mobile_number"
            value={person.mobile_number}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Address"
            name="address"
            value={person.address}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
        </>
      );
    }

    if (type === 'insurance') {
      return (
        <>
          <TextField
            label="Name"
            name="patient_insurance_name"
            value={person.patient_insurance_name}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Email"
            name="patient_insurance_email"
            value={person.patient_insurance_email}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Mobile Number"
            name="mobile_number"
            value={person.mobile_number}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Address"
            name="address"
            value={person.address}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
        </>
      );
    }

    return null;
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6">User Information</Typography>
        </Toolbar>
      </AppBar>
      <Box padding={4}>
        {isLoading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} className="flex justify-center">
              <Avatar src="/assets/imgs/male.png" sx={{ width: 100, height: 100 }} />
            </Grid>
            <Grid item xs={12}>
              {renderForm()}
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="secondary"
                fullWidth
                onClick={() => updatePatientsUserDetails(person)}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </div>
  );
};

export default UserInformationPage;
