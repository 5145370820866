import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Typography, List, ListItem, ListItemText, ListItemIcon, Switch, CircularProgress } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import axios from 'axios';

const NotificationPage = () => {
  const [isToggleEmailChecked, setIsToggleEmailChecked] = useState(false);
  const [isToggleSmsChecked, setIsToggleSmsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    fetchNotificationSettings();
  }, []);

  const fetchNotificationSettings = async () => {
    setLoading(true);
    try {
        /******
        TODO: Balkishan: Need to implement the api to get the current notification state.
        ******/
      const res = await axios.get('/path-to-fetch-notification'); // TODO: update url;
      if (res.status) {
        const { email, sms } = res.data;
        setIsToggleEmailChecked(email);
        setIsToggleSmsChecked(sms);
      }
    } catch (error) {
      console.error('Error fetching notification settings:', error);
    } finally {
      setLoading(false);
    }
  };

  const updateNotificationDetails = async () => {
    setLoading(true);
    const smsNotification = isToggleSmsChecked ? 1 : 0;
    const emailNotification = isToggleEmailChecked ? 1 : 0;
    
    try {
        // TODO: Balkishan: Need to implement the api here to update the notification
      const res = await axios.post('/path-to-update-notification', { sms: smsNotification, email: emailNotification });// TODO: Update URL
      if (res.status) {
        setMessage('The notification information successfully updated');
      } else {
        setMessage('The notification information not updated. Please try again');
      }
    } catch (error) {
      console.error('Error updating notification details:', error);
      setMessage('Error updating notification details. Please try again');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6">Notifications</Typography>
        </Toolbar>
      </AppBar>
      <div className="p-4">
        {loading && <CircularProgress />}
        {message && <Typography variant="body2" color="error">{message}</Typography>}
        <List>
          <ListItem>
            <ListItemIcon>
              <MailOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="Email" />
            <Switch
              checked={isToggleEmailChecked}
              onChange={() => { setIsToggleEmailChecked(!isToggleEmailChecked); updateNotificationDetails(); }}
              color="primary"
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <ChatBubbleOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="SMS" />
            <Switch
              checked={isToggleSmsChecked}
              onChange={() => { setIsToggleSmsChecked(!isToggleSmsChecked); updateNotificationDetails(); }}
              color="primary"
            />
          </ListItem>
        </List>
      </div>
    </div>
  );
};

export default NotificationPage;
