import { AuthContext } from "../Context/AuthContext";
import { toast } from "react-toastify";
import { Typography, TextField, Button, Box, Grid } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Navbar from "../components/Navbar";

function GetDetails() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const { setIsAuthenticated, setUser, setToken } = useContext(AuthContext);

  const [params] = useSearchParams();
  const { state } = useLocation();
  const navigateTo = useNavigate();

  useEffect(() => {
    if (!state) return;
    if (state.firstName) setFirstName(state?.firstName || "");
    if (state.lastName) setLastName(state?.lastName || "");
  }, [state]);

  useEffect(() => {
    const email = params.get("email") === "true";
    const appleSub = !!params.get("appleSub");

    if (email && !appleSub) {
      navigateTo("/login");
    }
  }, [params, navigateTo]);

  async function handleRegistration(e) {
    e.preventDefault();
    try {
      if (!state?.email && params.get("email") !== "true") {
        navigateTo("/login");
      }
      let api_body = {
        firstName,
        lastName,
        phone,
        email: params.get("email") === "true" ? email : state.email,
        appleSub: params.get("appleSub"),
      };

      if (params.get("password") === "true") {
        api_body = { ...api_body, password };
      }
      const [data, axiosError] = await axios
        .post(import.meta.env.VITE_APP_BASE_API + import.meta.env.VITE_APP_API_VERSION + "/user/patient/details?token=true", api_body, {
          withCredentials: true,
          headers: { "Content-Type": "application/json" },
        })
        .then(({ data }) => [data, undefined])
        .catch((err) => [undefined, err]);

      if (axiosError) {
        // if (axiosError.response.status === 403) {
        //   navigateTo("/register/get-details");
        //   toast.info(axiosError.response.data.message);
        //   return;
        // }

        toast.error(axiosError.response.data.message);
        return;
      }

      toast.success(data.message);
      setIsAuthenticated(true);
      setUser(data.user);
      setToken(data.token);
      navigateTo("/");
      setPassword("");
      setFirstName("");
      setLastName("");
      setPhone("");
    } catch (err) {
      console.error(err);
      toast.error("Some Error Occured");
    }
  }
  return (
    <>
      <Navbar />
      <div className="container form-component login-form">
        <Box my={4}>
          <h2>Sign Up</h2>
          <Typography variant="body1" gutterBottom>
            Please Sign Up To Continue
          </Typography>
          <Typography variant="body2" gutterBottom>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat culpa voluptas expedita itaque ex, totam ad quod error?
          </Typography>
          <form onSubmit={handleRegistration}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <TextField fullWidth label="First Name" variant="outlined" margin="normal" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField fullWidth label="Last Name" variant="outlined" margin="normal" value={lastName} onChange={(e) => setLastName(e.target.value)} />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField fullWidth label="Mobile Number" type="number" variant="outlined" margin="normal" value={phone} onChange={(e) => setPhone(e.target.value)} />
              </Grid>
              {params.get("password") === "true" && (
                <Grid item xs={12} sm={12} md={6}>
                  <TextField fullWidth label="Password" type="password" variant="outlined" margin="normal" value={password} onChange={(e) => setPassword(e.target.value)} />
                </Grid>
              )}

              {params.get("email") === "true" && (
                <Grid item xs={12} sm={12} md={6}>
                  <TextField fullWidth label="Email" type="email" variant="outlined" margin="normal" value={email} onChange={(e) => setEmail(e.target.value)} />
                </Grid>
              )}
            </Grid>
            {/* <Box display="flex" justifyContent="end" alignItems="center">
              <Link to={"/login"} style={{ textDecoration: "none", color: "#271776ca" }}>
                <Typography variant="body2" component="span" className="text-right">
                  Already Registered?
                </Typography>{" "}
                <Typography variant="body2" component="span" color={"primary"}>
                  Login Now
                </Typography>
              </Link>
            </Box> */}
            <Grid container justifyContent="center">
              <Button type="submit" variant="contained" color="primary" style={{ marginTop: "20px" }} className="submit-button">
                Continue
              </Button>
            </Grid>
          </form>
        </Box>
      </div>
    </>
  );
}

export default GetDetails;
