import Hero from "../../components/Hero";
import Navbar from "../../components/Navbar";
import Card from "./Card";


const ShareData = () => {

  return (
    <>
     <Navbar /> 
    {/* set bg to a image file */}
    <div className="mt-[100px] h-screen dash-main-bg bg-cover bg-center">
    <h1 className="text-3xl font-semibold text-center text-white">Share Data</h1>
    <div className="grid grid-cols-2 gap-6 p-10 rounded-lg shadow-lg max-w-5xl mx-auto md:grid-cols-3">
        <Card icon="/cards/share-data/sensors.png" title="Vitals" />
        <Card icon="/cards/share-data/answers.png" title="Answers" onClick={() => window.location.href = "/dashboard/share-data/answers"} />
        <Card icon="/cards/share-data/billing.png" title="RCM billing" />
        <Card icon="/cards/share-data/cgm.png" title="CGM" />
        
      </div>
    </div>
    </>
  );
};

export default ShareData;
