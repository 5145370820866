import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { IoMdArrowBack, IoMdNotifications, IoMdSettings } from "react-icons/io";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  FaBook,
  FaEnvelope,
  FaFacebook,
  FaLinkedin,
  FaList,
  FaMapPin,
  FaPhone,
  FaTwitter,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function Profile() {
  const navigate = useNavigate();

  const goto = (path) => {
    navigate(`/${path}`);
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="back"
            sx={{ mr: 2 }}
          >
            <IoMdArrowBack />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Profile
          </Typography>
          <Button color="inherit">Edit</Button>
        </Toolbar>
      </AppBar>

      <ListItem alignItems="flex-start" className="mt-14">
        <ListItemText
          primary={
            <React.Fragment>
              <Typography sx={{ display: "inline", marginTop: "20px", fontWeight: 600 }}
                component="h5"
                variant="h5"
                color="text.primary">
              Wellwrap1
              </Typography>
            </React.Fragment>
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: "inline", marginTop: "20px" }}
                component="span"
                variant="body2"
                color="text.primary"
              ></Typography>
            </React.Fragment>
          }
        />
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src="/public/signin.png" />
        </ListItemAvatar>
      </ListItem>
      <Toolbar>
        <p>Verified with: </p>
        <IconButton aria-label="" color="primary">
          <FaFacebook />
        </IconButton>
        <IconButton aria-label="" color="dark">
          <FaTwitter />
        </IconButton>
        <IconButton aria-label="" color="dark">
          <FaLinkedin />
        </IconButton>
      </Toolbar>

      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <FaEnvelope />
            </ListItemIcon>
            <ListItemText primary="Wellwrap1@gmail.com " />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <FaPhone />
            </ListItemIcon>
            <ListItemText primary="9253361097" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <FaMapPin />
            </ListItemIcon>
            <ListItemText primary="Location" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding onClick={() => goto('settings')}>
          <ListItemButton>
            <ListItemIcon>
              <IoMdSettings />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding onClick={() => goto('notifications')}>
          <ListItemButton>
            <ListItemIcon>
              <IoMdNotifications />
            </ListItemIcon>
            <ListItemText primary="Notification" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding onClick={() => goto('friends-list')}>
          <ListItemButton>
            <ListItemIcon>
              <FaList />
            </ListItemIcon>
            <ListItemText primary="Friends list" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding onClick={() => goto('notifications')}>
          <ListItemButton>
            <ListItemIcon>
              <FaList />
            </ListItemIcon>
            <ListItemText primary="Doctors List" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding onClick={() => goto('notifications')}>
          <ListItemButton>
            <ListItemIcon>
              <FaBook />
            </ListItemIcon>
            <ListItemText primary="Insurance List" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
}
