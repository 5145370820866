import { AuthContext } from "../Context/AuthContext";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Typography, TextField, Button, Box, Grid } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import AppleLogin from "react-apple-login";
import axios from "axios";
import Navbar from "../components/Navbar";

const Register = () => {
  const { isAuthenticated, setIsAuthenticated, setUser, setToken } = useContext(AuthContext);
  const [email, setEmail] = useState("");

  const navigateTo = useNavigate();

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => handleGoogleCode(tokenResponse.code),
    flow: "auth-code",
    scope: "email profile openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
  });

  useEffect(() => {
    setTimeout(() => {
      window.AppleID.auth.init({
        clientId: import.meta.env.VITE_APP_APPLE_CLIENTID,
        redirectURI: import.meta.env.VITE_APP_APPLE_REDIRECT_URL,
        usePopup: true,
      });
    }, 500);
  }, []);

  /**
   *
   * @param {string} code
   */
  async function handleGoogleCode(code) {
    try {
      const [data, axiosError] = await axios
        .post(
          import.meta.env.VITE_APP_BASE_API + import.meta.env.VITE_APP_API_VERSION + "/user/google/callback",
          { code, role: "Patient" },
          {
            withCredentials: true,
            headers: { "Content-Type": "application/json" },
          }
        )
        .then(({ data }) => [data, undefined])
        .catch((err) => [undefined, err]);

      if (axiosError) {
        if (axiosError.response.status === 403) {
          navigateTo("/register/get-details", {
            state: {
              ...axiosError.response.data.data,
            },
          });
          toast.info(axiosError.response.data.message);
          return;
        }

        toast.error(axiosError.response.data.message);
        return;
      }

      if (data.new_user === true) {
        navigateTo("/register/get-details", {
          state: {
            email: data?.data?.email,
            firstName: data?.data?.firstName,
            lastName: data?.data?.lastName,
          },
        });
        return;
      }

      toast.success(data.message);
      setIsAuthenticated(true);
      setUser(data.user);
      setToken(data.token);
      navigateTo("/");
      setEmail("");
    } catch (err) {
      console.error(err);
      toast.error("Some Error Occured");
    }
  }

  async function appleResponse(response) {
    if (response.error) {
      toast.error("Problem with apple signin");
      console.error(response.error);
      return;
    }
    const [data, error] = await axios
      .post(import.meta.env.VITE_APP_BASE_API + import.meta.env.VITE_APP_API_VERSION + "/user/apple/callback", {
        id_token: response.authorization.id_token,
        firstName: response?.user?.name?.firstName,
        lastName: response?.user?.name?.lastName,
        email: response?.user?.email,
        role: "Patient",
      })
      .then((res) => [res, undefined])
      .catch((err) => [undefined, err]);

    if (error) {
      if (error.response.status === 403) {
        navigateTo("/register/get-details?email=true&appleSub=" + error.response?.data?.data?.appleSub, {
          state: {
            ...error.response.data.data,
          },
        });
        toast.info(error.response.data.message);
        return;
      }

      toast.error(error.response.data.message);
      return;
    }

    if (data.data.new_user === true) {
      if (!data.data.data.email) {
        navigateTo("/register/get-details?email=true&appleSub=" + data.data.data.appleSub);
        return;
      }
      navigateTo("/register/get-details", {
        state: {
          email: data?.data?.email,
          firstName: data?.data?.firstName,
          lastName: data?.data?.lastName,
        },
      });
      return;
    }

    toast.success(data.message);
    setIsAuthenticated(true);
    setUser(data.user);
    setToken(data.token);
    navigateTo("/");
    setEmail("");
  }

  async function handleAccountCheck(e) {
    e.preventDefault();
    try {
      const [_data, axiosError] = await axios
        .post(
          import.meta.env.VITE_APP_BASE_API + import.meta.env.VITE_APP_API_VERSION + "/user/check-user",
          { email, role: "Patient" },
          {
            withCredentials: true,
            headers: { "Content-Type": "application/json" },
          }
        )
        .then(({ data }) => [data, undefined])
        .catch((err) => [undefined, err]);

      if (axiosError) {
        toast.error(axiosError.response.data.message);
        return;
      }

      navigateTo("/register/get-details?password=true", {
        state: {
          email: email.toLowerCase(),
        },
      });
    } catch (err) {
      console.error(err);
      toast.error("Some Error Occured");
    }
  }

  if (isAuthenticated) {
    return <Navigate to={"/"} />;
  }

  return (
    <>
      <Navbar />
      <div className="container form-component login-form">
        <Box my={4}>
          <h2>Sign Up</h2>
          <Typography variant="body1" gutterBottom>
            Please Sign Up To Continue
          </Typography>
          <Typography variant="body2" gutterBottom>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat culpa voluptas expedita itaque ex, totam ad quod error?
          </Typography>
          <div className="flex flex-col gap-3">
            <div className="flex justify-around">
              <button className="text-white bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#4285F4]/55 me-2 mb-2" onClick={googleLogin}>
                <svg className="w-4 h-4 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 19">
                  <path fillRule="evenodd" d="M8.842 18.083a8.8 8.8 0 0 1-8.65-8.948 8.841 8.841 0 0 1 8.8-8.652h.153a8.464 8.464 0 0 1 5.7 2.257l-2.193 2.038A5.27 5.27 0 0 0 9.09 3.4a5.882 5.882 0 0 0-.2 11.76h.124a5.091 5.091 0 0 0 5.248-4.057L14.3 11H9V8h8.34c.066.543.095 1.09.088 1.636-.086 5.053-3.463 8.449-8.4 8.449l-.186-.002Z" clipRule="evenodd" />
                </svg>
                Sign in with Google
              </button>
              <AppleLogin
                clientId={import.meta.env.VITE_APP_APPLE_CLIENTID}
                redirectURI={import.meta.env.VITE_APP_APPLE_REDIRECT_URL}
                usePopup={true}
                callback={appleResponse} // Catch the response
                scope="email name"
                // responseType="form_post"
                responseMode="form_post"
                render={(
                  renderProps //Custom Apple Sign in Button
                ) => (
                  <button type="button" className="text-white bg-[#050708] hover:bg-[#050708]/90 focus:ring-4 focus:outline-none focus:ring-[#050708]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#050708]/50 dark:hover:bg-[#050708]/30 me-2 mb-2" onClick={renderProps.onClick}>
                    <svg className="w-5 h-5 me-2 -ms-1" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="apple" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                      <path fill="currentColor" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"></path>
                    </svg>
                    Sign in with Apple
                  </button>
                )}
              />
              <button className="text-white bg-[#050708] hover:bg-[#050708]/90 focus:ring-4 focus:outline-none focus:ring-[#050708]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#050708]/50 dark:hover:bg-[#050708]/30 me-2 mb-2">
                <svg className="w-5 h-5 me-2 -ms-1" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="apple" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                  <path fill="currentColor" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"></path>
                </svg>
                Sign in with Apple
              </button>
            </div>
            <div>-OR-</div>
            <form onSubmit={handleAccountCheck}>
              <TextField fullWidth label="Email" type="email" variant="outlined" margin="normal" value={email} onChange={(e) => setEmail(e.target.value)} />
              {/* <TextField fullWidth label="Password" type="password" variant="outlined" margin="normal" value={password} onChange={(e) => setPassword(e.target.value)} /> */}
              <Box display="flex" justifyContent="end" alignItems="center">
                <Link to={"/login"} style={{ textDecoration: "none", color: "#271776ca" }}>
                  {" "}
                  <Typography variant="body2" component="span" className="text-right">
                    {" "}
                    Already have an account?{" "}
                  </Typography>{" "}
                  <Typography variant="body2" component="span" color={"primary"}>
                    Login instead
                  </Typography>
                </Link>
              </Box>
              <Grid container justifyContent="center">
                <Button className="submit-button" type="submit" variant="contained" color="primary" style={{ marginTop: "20px" }}>
                  Register Now
                </Button>
              </Grid>
            </form>
          </div>
        </Box>
      </div>
    </>
  );
};

export default Register;
