import React, { useState, useEffect, useContext } from 'react';
import LexChatbot from '../Chat/LexChatbot';
import { AuthContext } from '../Context/AuthContext';
import { Modal, Box } from '@mui/material';
const Chatbox = () => {
  const { isAuthenticated } = useContext(AuthContext); // Destructuring isAuthenticated from the context
  const [isOpen, setIsOpen] = useState(false);

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape' && isOpen) {
        setIsOpen(false);
      }
    };
    document.addEventListener('keydown', handleEscape);
    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen]);

  // Render nothing if the user is not authenticated
  if (!isAuthenticated) {
    return null;
  }

  return (
    <div className="fixed bottom-5 right-5 z-50">
      <button
        className="focus:outline-none"
        onClick={toggleChat}
        aria-label="Open chat"
        style={{ backgroundColor: 'transparent', border: 'none', padding: 0 }}
      >
        <img src="/imgs/robot.png" alt="Chat" className="animate-pulse h-20" />
      </button>

      <Modal 
  open={isOpen} keepMounted={true} 
  onClose={() => console.log("Close Modal")}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box className="mui-fixed fixed md:bottom-5 md:right-5 sm:bottom-0 sm:right bg-white rounded-lg shadow-lg w-full h-full md:w-96 md:h-[600px]">
  <LexChatbot isOpen={isOpen}  onToggle={toggleChat} />
  </Box>
</Modal>
    </div>
  );
};

export default Chatbox;
