import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, TextField, Button, IconButton, Grid, Box } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';

const FriendsListPage = () => {
  const [emails, setEmails] = useState(JSON.parse(localStorage.getItem('frndList')) || []);
  const [txtEmail, setTxtEmail] = useState('');
  const navigate = useNavigate();

  const addEmail = (mail) => {
    const updatedEmails = [...emails, mail];
    setEmails(updatedEmails);
    localStorage.setItem('frndList', JSON.stringify(updatedEmails));
    setTxtEmail('');
  };

  const openNotification = () => {
    navigate('/notifications');
  };

  const openInformation = () => {
    navigate('/user-information');
  };

  const removeEmail = (index) => {
    const updatedEmails = emails.filter((_, i) => i !== index);
    setEmails(updatedEmails);
    localStorage.setItem('frndList', JSON.stringify(updatedEmails));
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6">Friends List</Typography>
        </Toolbar>
      </AppBar>
      <Box padding={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Email"
              type="text"
              value={txtEmail}
              onChange={(e) => setTxtEmail(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => addEmail(txtEmail)}
            >
              Add Email
            </Button>
          </Grid>
        </Grid>
        <Box marginTop={4}>
          <Typography variant="h6">Friends</Typography>
          {emails.map((email, index) => (
            <Grid container spacing={2} key={index} alignItems="center">
              <Grid item xs={12} sm={9}>
                <Typography variant="body1"><b>{email}</b></Typography>
              </Grid>
              <Grid item xs={12} sm={1}>
                <IconButton onClick={openInformation}>
                  <InfoIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12} sm={1}>
                <IconButton onClick={openNotification}>
                  <NotificationsIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12} sm={1}>
                <IconButton onClick={() => removeEmail(index)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </Box>
      </Box>
    </div>
  );
};

export default FriendsListPage;
