import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Typography, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const SettingsPage = () => {
  const [appVersion, setAppVersion] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Mock the app version retrieval logic
    const getAppVersion = async () => {
      try {
        const version = '1.0.0'; // Replace this with actual version retrieval logic
        setAppVersion(version);
      } catch (error) {
        console.error('Error fetching app version:', error);
      }
    };

    getAppVersion();

    const checkAuthentication = async () => {
      try {
        const isAuthenticated = await axios.get('/path-to-auth-check'); // Replace with actual auth check logic
        if (!isAuthenticated) {
          navigate('/login');
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
        navigate('/login');
      }
    };

    checkAuthentication();
  }, [navigate]);

  const goto = (path) => {
    navigate(`/${path}`);
  };

  const logout = async () => {
    await axios
    .get(import.meta.env.VITE_APP_BASE_API + import.meta.env.VITE_APP_API_VERSION + "/user/patient/logout", {
      withCredentials: true,
    })
    .then((res) => {
      toast.success(res.data.message);
      setIsAuthenticated(false);
      setUser({});
      setToken(null);
      localStorage.removeItem("token");
      localStorage.removeItem('senseData');
      localStorage.removeItem('painData');
      navigate('/login');
    })
    .catch((err) => {
      toast.error(err.response.data.message);
    });
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6">
            Settings
          </Typography>
        </Toolbar>
      </AppBar>
      <div className="p-4">
        <div className="flex flex-col items-center">
          <img src="/assets/imgs/icon.png" alt="Logo" className="w-20 h-20" />
          <Typography variant="h5" className="mt-2">SmartNurse</Typography>
          <Typography variant="body2" className="mt-1">v.{appVersion}</Typography>
        </div>
        <List className="mt-4">
          <ListItem button onClick={() => goto('settings')}>
            <ListItemText primary="Hippa compliant data" />
            <ListItemIcon>
              <ArrowForwardIosIcon />
            </ListItemIcon>
          </ListItem>
          <ListItem button onClick={() => goto('how')}>
            <ListItemText primary="How it Works" />
            <ListItemIcon>
              <ArrowForwardIosIcon />
            </ListItemIcon> 
          </ListItem>
          <ListItem button onClick={() => goto('faq')}>
            <ListItemText primary="FAQ" />
            <ListItemIcon>
              <ArrowForwardIosIcon />
            </ListItemIcon>
          </ListItem>
          <ListItem button onClick={() => goto('privacy')}>
            <ListItemText primary="Privacy policy" />
            <ListItemIcon>
              <ArrowForwardIosIcon />
            </ListItemIcon>
          </ListItem>
          <ListItem button onClick={() => goto('terms')}>
            <ListItemText primary="Terms & Conditions" />
            <ListItemIcon>
              <ArrowForwardIosIcon />
            </ListItemIcon>
          </ListItem>
          <ListItem button onClick={logout}>
            <ListItemText primary="Logout" />
            <ListItemIcon>
              <ArrowForwardIosIcon />
            </ListItemIcon>
          </ListItem>
        </List>
      </div>
    </div>
  );
};

export default SettingsPage;
