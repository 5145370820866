import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from '../../components/Navbar';
import Modal from './EditModal';  // Import the Modal component

interface Doctor {
  firstName: string;
  lastName: string;
}

interface Appointment {
  _id: string;
  doctor: Doctor;
  firstName: string;
  lastName: string;
  appointment_date: string;
  department: string;
  status: 'Pending' | 'Accepted' | 'Rejected';
}

const AppointmentsDashboard: React.FC = () => {
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [editingAppointment, setEditingAppointment] = useState<Appointment | null>(null);

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const response = await axios.get(
          import.meta.env.VITE_APP_BASE_API + import.meta.env.VITE_APP_API_VERSION + '/appointment/patient/all',
          {
            withCredentials: true,
          }
        );
        setAppointments(response.data.appointments);
      } catch (error) {
        if(error.response.status === 400) {
          window.location.href = '/login';
        }
        console.error('Error fetching appointments:', error);
      }
    };

    fetchAppointments();
  }, []);

  const getStatusColor = (status: Appointment['status']) => {
    switch (status) {
      case 'Pending':
        return 'bg-yellow-300';
      case 'Accepted':
        return 'bg-green-300';
      case 'Rejected':
        return 'bg-red-300';
      default:
        return 'bg-gray-300';
    }
  };

  const handleEditClick = (appointment: Appointment) => {
    setEditingAppointment(appointment);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (editingAppointment) {
      setEditingAppointment({
        ...editingAppointment,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSaveClick = async () => {
    if (editingAppointment) {
      try {
        await axios.put(
          import.meta.env.VITE_APP_BASE_API + import.meta.env.VITE_APP_API_VERSION + `/appointment/patient/update/${editingAppointment._id}`,
          editingAppointment,
          {
            withCredentials: true,
          }
        );
        setAppointments(appointments.map(appointment =>
          appointment._id === editingAppointment._id ? editingAppointment : appointment
        ));
        setEditingAppointment(null);
      } catch (error) {
        console.error('Error updating appointment:', error);
      }
    }
  };

  const closeModal = () => {
    setEditingAppointment(null);
  };

  return (
    <div className="min-h-screen flex flex-col items-center bg-gray-100 p-5">
      <Navbar />
      <div className="w-full max-w-4xl p-6 bg-blue-200 rounded-lg shadow-lg mt-[100px]">
        <h1 className="text-2xl font-bold mb-5">Appointments</h1>
        <div className="w-full max-w-4xl space-y-4">
          {appointments.map((appointment) => (
            <div
              key={appointment._id}
              className={`p-4 rounded-lg shadow-md flex justify-between items-center ${getStatusColor(appointment.status)}`}
            >
              <div>
                <h2 className="text-lg font-bold">
                  Patient: {appointment.firstName} {appointment.lastName}
                </h2>
                <p>Doctor: {appointment.doctor.firstName} {appointment.doctor.lastName}</p>
                <p>Department: {appointment.department}</p>
                <p>Date: {appointment.appointment_date}</p>
                <p>Status: {appointment.status}</p>
              </div>
              <div>
                <button
                  onClick={() => handleEditClick(appointment)}
                  className="text-blue-500 hover:underline"
                >
                  Edit
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Edit form inside Modal */}
      <Modal isOpen={!!editingAppointment} onClose={closeModal}>
        {editingAppointment && (
          <div>
            <h2 className="text-xl font-bold mb-4">Edit Appointment</h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium">Patient First Name</label>
                <input
                  type="text"
                  name="firstName"
                  value={editingAppointment.firstName}
                  onChange={handleInputChange}
                  className="w-full border border-gray-300 rounded-lg p-2"
                />
              </div>
              <div>
                <label className="block text-sm font-medium">Patient Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  value={editingAppointment.lastName}
                  onChange={handleInputChange}
                  className="w-full border border-gray-300 rounded-lg p-2"
                />
              </div>
              <div>
                <label className="block text-sm font-medium">Status</label>
                <select
                  name="status"
                  value={editingAppointment.status}
                  onChange={handleInputChange}
                  className="w-full border border-gray-300 rounded-lg p-2"
                >
                  <option value="Pending">Pending</option>
                  <option value="Accepted">Accepted</option>
                  <option value="Rejected">Rejected</option>
                </select>
              </div>
              <button
                onClick={handleSaveClick}
                className="bg-green-500 text-white p-2 rounded-lg mt-4"
              >
                Save
              </button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default AppointmentsDashboard;
